/* headers */
h1 {
	@apply text-36 lg:text-5xl xl:text-60;
}

h2 {
	@apply text-20 lg:text-4xl xl:text-40;
}

h3 {
	@apply text-16 lg:text-30;
}

h4 {
	@apply text-xl lg:text-25;
}

h5 {
	@apply text-lg lg:text-20;
}

h6 {
	@apply text-base;
}

/* link */
.link {
	@apply cursor-pointer text-examine-purple-400 hover:underline;
}

/* overview */
.overview {
	@apply max-w-3xl pt-4 lg:pt-7 text-lg lg:text-xl -tracking-2;
}

.overview a {
	@apply text-white underline hover:text-examine-purple-400;
}

.overview a sup {
	@apply text-white underline hover:text-examine-purple-400 font-normal;
}

/* main content */
.text-content {
	@apply -tracking-2 leading-7 xl:text-xl xl:leading-9;
}

.content {
	/* apply prose styles */
	@apply prose lg:prose-lg xl:prose-xl;

	/* default */
	@apply text-gray-900 font-inter text-content;

	/* overflow */
	@apply overflow-x-auto md:overflow-x-visible;

	/* paragraph */
	@apply prose-p:py-2.5;

	/* blockquote */
	@apply prose-blockquote:border-l-4 prose-blockquote:border-info prose-blockquote:py-2 prose-blockquote:pl-4 prose-blockquote:text-primary xl:prose-blockquote:pl-6 xl:prose-blockquote:text-xl;

	/* strong */
	@apply prose-strong:font-bold;

	/* link */
	@apply prose-a:text-examine-purple-400 prose-a:no-underline hover:prose-a:underline prose-a:font-normal;

	/* headings */
	@apply prose-headings:my-3 prose-headings:font-lora prose-headings:font-medium prose-headings:text-primary;

	/* h1 */
	@apply prose-h1:text-[28px] prose-h1:leading-normal xl:prose-h1:text-3xl 2xl:prose-h1:text-4xl;

	/* h2 */
	@apply prose-h2:text-2xl xl:prose-h2:text-25 2xl:prose-h2:text-30 3xl:prose-h2:text-40;

	/* h3 */
	@apply prose-h3:text-xl xl:prose-h3:text-22 3xl:prose-h3:text-[28px] 3xl:prose-h3:leading-9;

	/* h4 */
	@apply prose-h4:text-lg xl:prose-h4:text-xl 3xl:prose-h4:text-[26px] xl:prose-h4:leading-8;

	/* h5 */
	@apply prose-h5:text-2xl;

	/* h6 */
	@apply prose-h6:[&:not([role=tooltip])]:text-[22px];

	/* ul */
	@apply prose-ul:ml-10 prose-ul:my-0 prose-ul:py-2.5 prose-ul:pl-0 prose-ul:list-circle;

	/* ol */
	@apply prose-ol:ml-10 prose-ol:my-0 prose-ol:py-2.5 prose-ol:pl-0 prose-ol:list-decimal;

	/* li */
	@apply marker:prose-li:text-primary prose-li:text-gray-900 prose-li:py-0 prose-li:my-0 prose-li:px-0 prose-li:leading-7 xl:prose-li:leading-9;

	/* images */
	@apply prose-img:my-0;

	/* table */
	@apply prose-th:font-inter;
}

/* small */
.content p small {
	line-height: -20px !important;
}

/* paragraph before ul */
.content p:has(+ul) {
	padding-bottom: 0 !important;
}

/* ul after paragraph */
.content p+ul {
	padding-top: 0 !important;
}

/* paragraph before ol */
.content p:has(+ol) {
	padding-bottom: 0 !important;
}

/* ol after paragraph */
.content p+ol {
	padding-top: 0 !important;
}

/* sup */
.content a sup {
	font-weight: normal !important;
}

.content.gpt {
	@apply text-base leading-7;

	/* li */
	@apply prose-li:text-base prose-li:leading-7;
}

/* search content */
.search-content {
	@apply mb-6 mt-2 pl-4 xl:pl-6 border-l-4 border-info leading-6 text-gray-900;
}

.search-content a {
	@apply text-examine-purple-400 hover:underline;
}

/* alert content */
.alert-content {
	/* apply prose styles */
	@apply prose lg:prose-lg xl:prose-xl;

	/* default */
	@apply text-gray-900 font-inter -tracking-2 text-base md:text-lg md:leading-6 xl:leading-7;

	/* overflow */
	@apply overflow-x-auto md:overflow-x-visible;

	/* paragraph */
	@apply prose-p:py-1.5;

	/* blockquote */
	@apply prose-blockquote:border-l-4 prose-blockquote:border-info prose-blockquote:py-2 prose-blockquote:pl-4 prose-blockquote:text-primary xl:prose-blockquote:pl-6 xl:prose-blockquote:text-xl;

	/* strong */
	@apply prose-strong:font-bold;

	/* link */
	@apply prose-a:text-examine-purple-400 prose-a:no-underline hover:prose-a:underline prose-a:font-normal;

	/* headings */
	@apply prose-headings:my-2 prose-headings:font-inter prose-headings:font-medium;

	/* h1 */
	@apply prose-h1:text-[28px] prose-h1:leading-normal xl:prose-h1:text-3xl 2xl:prose-h1:text-4xl;

	/* h2 */
	@apply prose-h2:text-2xl xl:prose-h2:text-25 2xl:prose-h2:text-30 3xl:prose-h2:text-40;

	/* h3 */
	@apply prose-h3:text-xl xl:prose-h3:text-22 3xl:prose-h3:text-[28px] 3xl:prose-h3:leading-9;

	/* h4 */
	@apply prose-h4:text-lg xl:prose-h4:text-xl 3xl:prose-h4:text-[26px] xl:prose-h4:leading-8;

	/* h5 */
	@apply prose-h5:text-lg lg:prose-h5:text-20;

	/* h6 */
	@apply prose-h6:[&:not([role=tooltip])]:text-base;

	/* ul */
	@apply prose-ul:ml-0 prose-ul:my-0 prose-ul:py-1 prose-ul:pl-4 sm:prose-ul:pl-8 prose-ul:list-disc prose-ul:list-outside;

	/* ol */
	@apply prose-ol:ml-0 prose-ol:my-0 prose-ol:py-1 prose-ol:pl-4 sm:prose-ol:pl-8 prose-ol:list-decimal prose-ol:list-outside;

	/* li */
	@apply marker:prose-li:text-primary prose-li:text-gray-900 prose-li:py-0 prose-li:my-0 prose-li:px-0 md:prose-li:leading-6 xl:prose-li:leading-7;

	/* images */
	@apply prose-img:my-0;
}

.alert-content li p {
	@apply py-0;
}

/* paragraph before ul */
.alert-content p:has(+ul) {
	padding-bottom: 0 !important;
}

/* ul after paragraph */
.alert-content p+ul {
	padding-top: 0 !important;
}

/* paragraph before ol */
.alert-content p:has(+ol) {
	padding-bottom: 0 !important;
}

/* ol after paragraph */
.alert-content p+ol {
	padding-top: 0 !important;
}

/* Deprecated: added for compatibility on custom pages */
.page-content,
.summary {
	@apply content;
}